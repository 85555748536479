import moment from 'moment';
import store from '../store/index.js';

export const isOpen = () => {
    const config = store.state.configuracoes;
    const isEmpBlocked = !config.ativo;

    if (isEmpBlocked) {
        return {
            text: 'FECHADO',
            color: 'error',
            status: false,
        };
    }

    const hoje = config.horarios?.find(f => f.isoWeekday == moment().isoWeekday());

    let horarios = hoje?.horario?.split(' - ');
    let horarios2 = hoje?.segundoHorarioAtivo ? hoje?.segundoHorario?.split(' - ') : '';

    if (horarios) {
        const currentTime = moment();
        const abertura1 = moment(horarios[0], 'HH:mm');
        const fechamento1 = moment(horarios[1], 'HH:mm');
        const abertura2 = horarios2 ? moment(horarios2[0], 'HH:mm') : '';
        const fechamento2 = horarios2 ? moment(horarios2[1], 'HH:mm') : '';

        const abertoAgora1 = currentTime.isBetween(abertura1, fechamento1);
        const abertoAgora2 = horarios2 ? currentTime.isBetween(abertura2, fechamento2) : false;
        const intervalo = horarios2 ? currentTime.isBetween(fechamento1, abertura2) : false;
        const jaFechou = currentTime.isAfter(horarios2 ? fechamento2 : fechamento1);
        const aindaNaoAbriu = currentTime.isBefore(abertura1);

        if (hoje?.aberto) {
            if (aindaNaoAbriu) {
                return {
                    text: 'FECHADO, ABRE ÀS ' + abertura1.format('HH:mm'),
                    color: 'error',
                    status: false,
                };
            }
            if (abertoAgora1) {
                return {
                    text: 'ABERTO ATÉ ÀS ' + fechamento1.format('HH:mm'),
                    color: 'success',
                    status: true,
                };
            }
            if (abertoAgora2) {
                return {
                    text: 'ABERTO ATÉ ÀS ' + fechamento2.format('HH:mm'),
                    color: 'success',
                    status: true,
                };
            }
            if (jaFechou) {
                return {
                    text: 'FECHADO',
                    color: 'error',
                    status: false
                };
            }
            if (intervalo) {
                return {
                    text: 'FECHADO, ABRE ÀS ' + abertura2.format('HH:mm'),
                    color: 'error',
                    status: false,
                };
            }
        } else {
            return { text: 'FECHADO HOJE', color: 'error', status: false };
        }
    }
    return { text: 'FECHADO', color: 'error', status: false };
}

export const requireScheduling = () => {
    const config = store.state.configuracoes;

    const open = isOpen();

    if (!config.agendamento?.ativo) {
        return false;
    }

    return config.agendamento?.obrigatorio || !open.status;
}
