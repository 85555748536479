<template>
    <v-app>
        <slot name="menu" />

        <slot name="principal" />

        <v-dialog :value="banner.visible" content-class="elevation-0" width="unset">
            <v-card>
                <v-card-text>
                    <img
                        :src="banner.img"
                        :style="{
                            'max-height': $vuetify.breakpoint.height - 130 + 'px',
                            'max-width': $vuetify.breakpoint.width - 90 + 'px'
                        }"
                    >
                    <div>
                        <v-btn
                            @click="banner.visible = false"
                            :style="{left: '50%', transform: 'translateX(-50%)'}"
                            block
                            color="primary"
                        >Continuar</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <Confirm ref="confirm" />

        <Login
            v-if="showLoginDialog"
            @login="onLogin"
            @close="showLoginDialog = false"
        />

        <MyAccount
            v-if="showMyAccountDialog"
            @close="showMyAccountDialog = false"
        />

        <v-snackbar
            v-model="snackbar.show"
            :timeout="2500"
            centered
            elevation="12"
            fixed
            top
            :color="snackbar.type == 'success' ? 'green accent-4' : 'error'"
        >
            <span>
                <v-icon color="white">
                    mdi-{{ snackbar.type === 'success' ? 'check-circle-outline' : 'close-circle-outline' }}
                </v-icon>
                {{ snackbar.text }}
            </span>
        </v-snackbar>
    </v-app>
</template>

<script>
import Confirm from './Confirm';
import Login from '@/pages/auth/Login';
import MyAccount from '@/pages/auth/MyAccount';

export default {
    name: 'BaseContainer',

    components: {
        Confirm,
        Login,
        MyAccount,
    },

    data: () => ({
        snackbar: {show: false, text: '', type: ''},
        banner: {
            visible: false,
            img: '',
        },
        showLoginDialog: false,
        showMyAccountDialog: false,
    }),

    mounted() {
        this.$root.$on('notify', data => (this.setSnackbar(data)));
        this.$root.$on('banner', data => (this.setBanner(data)));
        this.$root.$on('login', () => (this.showLoginDialog = true));
        this.$root.$on('myAccount', () => (this.showMyAccountDialog = true));

        this.$root.$confirm = this.$refs.confirm.open;
    },

    methods: {
        setSnackbar(data) {
            data.show = true;
            this.snackbar = data;
        },

        setBanner(images) {
            const image = images[0];

            if (!image?.url || this.$store.getters.getBannerDisplayed) {
                return;
            }

            this.banner = {
                visible: true,
                img: this.getUrl(image),
            }

            this.$store.commit('setBannerDisplayed', true);
        },

        getUrl(img) {
            const url = img.tmp
                ? `${this.$urlAPI}/files/tmp/${img.url}`
                : `${this.$urlAPI}/storage/${img.url}`;

            return url;
        },

        onLogin() {
            this.showLoginDialog = false;
            this.notify('Login efetuado com sucesso');
        },
    }
};
</script>

<style scoped>
.v-card__text {
    padding: 6px !important;
}
</style>