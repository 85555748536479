
<template>
    <PasswordReset
        v-if="showPasswordResetDialog"
        v-model="whatsapp"
        @reset="onReset"
        @login="showLogin"
        @close="showPasswordResetDialog = false"
    />
    <v-dialog v-else :value="true" persistent max-width="600">
        <v-card>
            <v-card-title class="headline">
                <v-spacer/>
                {{ hasAccount ? 'Digite sua senha' : 'Digite seu Whatsapp' }}
                <v-spacer/>
                <v-btn
                    @click="$emit('close')"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="text-center mt-4">
                <v-text-field
                    ref="whatsapp"
                    type="tel"
                    label="Whatsapp"
                    v-model="whatsapp"
                    placeholder="(99) 99999-9999"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    outlined
                    :disabled="hasAccount"
                    @keypress.enter="continuar"
                />
                <v-text-field
                    v-if="hasAccount"
                    ref="password"
                    label="Senha"
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    @keypress.enter="login"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    dense
                />
                <div v-if="hasAccount" style="display: flex;">
                    <v-btn
                        @click="forgotPassword"
                        color="primary"
                        text
                        x-small
                    >Esqueci minha senha</v-btn>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    @click="continuar"
                    :loading="loading"
                    color="primary"
                    class="white--text"
                    block
                    large
                >
                    {{ hasAccount ? 'Entrar' : 'Continuar' }}
                    <v-icon class="ml-1">mdi-arrow-right</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>

        <CreateAccount
            v-if="showCreateAccountDialog"
            v-model="whatsapp"
            @create="setToken"
            @close="showCreateAccountDialog = false"
        />
    </v-dialog>
</template>

<script>
import PasswordReset from './PasswordReset';
import CreateAccount from './CreateAccount';
import { mapActions } from 'vuex';

export default {
    name: 'Login',

    components: {
        PasswordReset,
        CreateAccount,
    },

    data: () => ({
        loading: false,
        showPassword: false,
        hasAccount: false,
        whatsapp: '',
        password: '',
        showCreateAccountDialog: false,
        showPasswordResetDialog: false,
    }),

    mounted() {
        setTimeout(() => this.$refs.whatsapp.focus(), 100);
    },

    methods: {
        ...mapActions([
            'userAuthenticate',
        ]),

        continuar() {
            this.hasAccount ? this.login() : this.checkWhatsapp();
        },

        checkWhatsapp() {
            if (this.whatsapp.length < 10) {
                this.notify('Whatsapp inválido', 'warning');
                setTimeout(() => this.$refs.whatsapp.focus(), 100);
                return;
            }

            this.loading = true;
            this.$http.get(`user/has-account/${this.whatsapp.replace(/\D/g, '')}`).then(resp => {
                const data = resp.data

                if (data.type === 'warning') {
                    this.notify(data.msg, 'warning');
                    return;
                }

                if (data.data) {
                    this.hasAccount = true;
                    setTimeout(() => this.$refs.password.focus(), 100);
                } else {
                    this.showCreateAccountDialog = true;
                }
            }).finally(() => {
                this.loading = false;
            });
        },

        login() {
            if (!this.password.length) {
                this.notify('Digite a senha', 'warning');
                setTimeout(() => this.$refs.password.focus(), 100);
                return;
            }

            this.loading = true;
            const data = { whatsapp: this.whatsapp, password: this.password };
            this.$http.post('login', data).then(resp => {
                console.log(resp);
                const data = resp.data

                if (data.type === 'warning') {
                    this.notify(data.msg, 'warning');
                    return;
                }

                this.setToken(data.data);
            }).finally(() => {
                this.loading = false;
            });
        },

        setToken(accessToken) {
            const name = this.$http.defaults.headers.common['emp'] + '-access-token';
            localStorage.setItem(name, accessToken);

            this.$http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

            this.userAuthenticate();

            this.$emit('login');
        },

        forgotPassword() {
            this.showPasswordResetDialog = true;
        },

        onReset(data) {
            this.setToken(data);
            this.$emit('login');
        },

        showLogin() {
            this.showPasswordResetDialog = false;
        },
    },
}
</script>
