
<template>
    <v-menu
        v-if="isLoggedIn"
        offset-y
        open-on-hover
        close-on-content-click
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                small
                rounded
                outlined
                color="white"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon small class="mr-1">mdi-account</v-icon>Oi, {{ getUserFirstName }}
            </v-btn>
        </template>
        <v-list>
            <v-list-item @click="myAccount">
                <v-list-item-title>
                    <v-icon class="mr-1">mdi-account</v-icon>
                    Minha Conta
                </v-list-item-title>
            </v-list-item>
            <v-list-item @click="sair">
                <v-list-item-title>
                    <v-icon class="mr-1" color="error">mdi-logout</v-icon>
                    Sair
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
    <v-btn
        v-else
        small
        rounded
        outlined
        color="white"
        @click="login"
    >
        <v-icon small class="mr-1">mdi-account</v-icon>Entrar
    </v-btn>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'Account',

    props: ['show'],

    data: () => ({
        myAccountShow: false,
    }),

    computed: {
        ...mapState([
            'usuario',
        ]),

        isLoggedIn() {
            return this.usuario?.id;
        },

        getUserFirstName() {
            return this.usuario.name ? this.usuario.name.split(' ')[0] : '';
        },
    },

    methods: {
        ...mapActions([
            'logout',
        ]),

        login() {
            this.$root.$emit('login');
        },

        myAccount() {
            this.$root.$emit('myAccount');
        },

        async sair() {
            if (!await this.$root.$confirm('Confirmação', 'Deseja realmente sair?', { color: 'red darken-1' })) {
                return;
            }

            const domain = this.$http.defaults.headers.common['emp'];
            localStorage.removeItem(domain + '-access-token');

            this.logout();

            this.$emit('logout');
        },
    },
}
</script>

<style scoped>
</style>
