<template>
    <router-view v-if="loaded" />
    <splash v-else />
</template>

<script>
import Vue from 'vue';
import Splash from '@/components/Splash'
import { getContrast } from '@/utils/utils';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'App',

    components: { Splash },

    data: () => ({
        loaded: false,
    }),

    mounted() {
        this.initialize();
    },

    computed: {
        ...mapState([
            'usuario',
        ]),
    },

    methods: {
        ...mapActions([
            'userAuthenticate',
        ]),

        async initialize() {
            const { pathname, host } = new URL(document.location.href);
            const empDomain = pathname.substr(1).split('/')[0];
            const isCustomDomain = host !== 'app.zapermenu.com.br' && !host.includes('localhost');

            let domain;

            if (isCustomDomain) {
                const { data } = (await this.$http.post('check-custom-domain', { host })).data;
                domain = data;
            } else {
                domain = this.$route.params.emp || empDomain;
            }

            this.$http.defaults.headers.common['emp'] = domain;

            const accessToken = localStorage.getItem(domain + '-access-token');
            if (accessToken) {
                this.$http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                await this.userAuthenticate();
            }

            const { data } = (await this.$http.get('theme')).data;
            const tema = data || {};

            const isDark = tema.isDark;
            const theme = isDark ? 'dark' : 'light';

            this.$vuetify.theme.isDark = isDark;
            this.$vuetify.theme.custom = tema;
            this.$vuetify.theme.contrast = tema?.appBarColor ? getContrast(tema.appBarColor.substring(0, 7)) : {};
            Vue.prototype.$theme = this.$vuetify.theme.themes[theme];
            this.$vuetify.theme.themes[theme].primary = tema.primaryColor;

            this.$store.commit('setAds', this.$route.query.ads);
            this.$store.commit('setMesa', this.$route.query.mesa);
            this.$store.commit('setCupom', this.$route.query.cupom);

            this.loaded = true;

            isCustomDomain && this.$router.push('/cardapio');
        },
    },
};
</script>

<style scoped>
</style>