export const clone = obj => JSON.parse(JSON.stringify(obj));

export const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export const getContrast = (hex) => {
    const { r, g, b} = hexToRgb(hex);

    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
        ? {theme: 'dark', color: '#000'}
        : {theme: 'light', color: '#fff'};
}

export const isMobile = () => window.matchMedia('(any-pointer:coarse)').matches;

// const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)

// const getChunksFromString = (st, chunkSize) => st.match(new RegExp(`.{${chunkSize}}`, "g"))

// const convertHexUnitTo256 = (hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16)

// const getAlphafloat = (a, alpha) => {
//     if (typeof a !== "undefined") {return a / 255}
//     if ((typeof alpha != "number") || alpha <0 || alpha >1){
//       return 1
//     }
//     return alpha
// }

// export const hexToRGBA = (hex, alpha) => {
//     if (!isValidHex(hex)) {throw new Error("Invalid HEX")}
//     const chunkSize = Math.floor((hex.length - 1) / 3)
//     const hexArr = getChunksFromString(hex.slice(1), chunkSize)
//     const [r, g, b, a] = hexArr.map(convertHexUnitTo256)
//     return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`
// }