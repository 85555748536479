<template>
    <v-data-table
        fixed-header
        :items="dados"
        :headers="headers"
        :loading="loading"
        :mobileBreakpoint="0"
        :height="300"
        :disable-pagination="true"
        :hide-default-footer="true"
    >
        <template v-slot:item.id="{item}">
            <span style="color: blue; text-decoration: underline; cursor: pointer;">
                {{ item.id }}
            </span>
        </template>
        <template v-slot:item.created_at="{item}">
            {{ formatDate(item.created_at) }}
        </template>
        <template v-slot:item.total="{item}">
            <span style="color: green;">
                R$&nbsp;{{ formatPrice(+item.total) }}
            </span>
        </template>
        <template v-slot:item.status="{item}">
            <v-chip
                class="white--text"
                :color="item.status == 'cancelado' ? 'red' : 'green'"
            >
                {{ item.status }}
            </v-chip>
        </template>
    </v-data-table>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'MyOrders',

    data: () => ({
        loading: false,
        dados: [],
        headers: [
            { text: 'Nº', value: 'id' },
            { text: 'Data/Hora', value: 'created_at',  align: 'center' },
            { text: 'R$ Total', value: 'total' },
            { text: 'Status', value: 'status' },
        ],
    }),

    computed: {
        ...mapState([
            'cliente',
        ]),
    },

    mounted() {
        this.consultar();
    },

    methods: {
        consultar() {
            this.dados = [];

            if (!this.cliente?.id) {
                return;
            }

            this.loading = true;
            this.$http.get('account/pedidos').then(resp => {
                this.dados = resp.data.data;
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },
    },
}
</script>

<style scoped>
.class-on-data-table table {
    table-layout: fixed;
}
</style>
